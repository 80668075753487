<template>
  <div :class="`custom-period ${period === 'custom' && 'selected'}`">
    <div class="custom-period-content">
      <DateRangePicker :dateRange="{ startDate, endDate }" @update="onChange" :class="`${_class ? _class : ''}`" :singleDatePicker="single" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["startDate", "endDate", "period", "_class", "single"],
  methods: {
    onChange: function({ startDate, endDate }) {
      startDate = startDate.toISOString().substring(0, 10);
      endDate = endDate.toISOString().substring(0, 10);
      this.$emit("change", { period: "custom", startDate, endDate });
    },
  },
};
</script>

<style lang="scss">
.custom-period {
  position: relative;
  padding: 0 $mpadding $mpadding $mpadding;
  &-content {
    border-top: solid 1px $alto;
    padding: $mpadding 0 0 0;
    .vue-daterange-picker {
      display: block;
    }
    .reportrange-text {
      text-align: center;
      border-radius: 2pc;
      padding: $mpadding/1.7;
      border: none;
      background-color: $gray2;
    }
  }
  &.selected {
    .reportrange-text {
      background-color: $primary_color_saturate;
    }
  }
}
</style>
